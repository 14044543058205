import { defineStore } from 'pinia';

export const useToastsStore = defineStore('toasts', {
    state: () => ({
        messages: [] as {
            id: string|number;
            title?: string;
            message?: string;
            duration?: number;
            status?: string;
        }[]
    }),

    actions: {
        addMessage(
            payload: {
                title: string,
                message: string,
                status?: string,
                duration?: number,
                id?: string|number
            }
        ) {
            // Skip on duplicate ID
            if (payload.id && this.messages.findIndex(toast => toast.id === payload.id) !== -1) {
                return;
            }

            // Push to the list
            this.messages.push({
                ...payload,
                id: payload.id || Math.floor(Math.random() * 100000) + 1 // Generate random ID
            });
        },
    
        removeMessage(payload: string|number) {
            this.messages.splice(this.messages.findIndex(({ id }) => id === payload), 1);
        }
    }
});
